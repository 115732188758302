@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?tz7fwy');
  src:  url('fonts/icomoon.eot?tz7fwy#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?tz7fwy') format('truetype'),
    url('fonts/icomoon.woff?tz7fwy') format('woff'),
    url('fonts/icomoon.svg?tz7fwy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-font-"], [class*=" icon-font-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-font-calendar-check-o:before {
content: "\e900";
}
.icon-font-circle:before {
content: "\e901";
}
.icon-font-shield:before {
content: "\e902";
}
.icon-font-close:before {
content: "\e903";
}
.icon-font-check:before {
content: "\e904";
}
.icon-font-facebook-f:before {
content: "\e905";
}
.icon-font-linkedin-in:before {
content: "\e906";
}
.icon-font-map-marker-alt:before {
content: "\e907";
}
.icon-font-star:before {
content: "\e908";
}
.icon-font-bars:before {
content: "\e909";
}
.icon-font-clock:before {
content: "\e90a";
}
.icon-font-price-tags:before {
content: "\e90b";
}
.icon-font-users:before {
content: "\e90c";
}
.icon-font-chevron-right:before {
content: "\e90d";
}
.icon-font-chevron-left:before {
content: "\e90e";
}
.icon-font-search:before {
content: "\e90f";
}
.icon-font-cross:before {
content: "\ea0f";
}
